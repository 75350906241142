import {callGet, callPost} from "../../lib/api-private";
import {GetTenantResponse} from "../../generated-client/model/get-tenant-response";

export const getTenant = async () : Promise<GetTenantResponse> => {
  const data = await callGet("/tenant");
  return data;
};


export const postCallbackUri = async (callbackUri: string) : Promise<void> => {
  const data = await callPost("/tenant/gpt-callback", {callbackUri});
  return data;
};
