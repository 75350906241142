import { Icon } from '@chakra-ui/react';
import {
	MdPerson,
	MdHome,
} from 'react-icons/md';

// Dashboard Imports
import MainDashboard from 'views/dashboard/default';
import Profile from 'views/dashboard/profile';

// Auth Imports
import SignIn from 'views/auth/signIn';

const routes = [
	{
		name: 'Home',
		layout: '/dashboard',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Company details',
		layout: '/dashboard',
		path: '/profile',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: Profile,
		hidden: true,
	},
	{
		name: 'Log in',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: SignIn,
		hidden: true,
	},
];

export default routes;
