import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

async function callApi(options: object) {
    const accessToken = cookies.get('authToken');
    console.log(accessToken);
    const result = await axios({
        ...options,
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return result.data;
}

export function callGet(url: string, params?: any) {
    return callApi({
        url,
        method: 'GET',
        params,
    });
}

export function callPost(url: string, data: any) {
    return callApi({
        url,
        data,
        method: 'POST',
    });
}



export function callPatch(url: string, data?: any) {
    return callApi({
        url,
        data,
        method: 'PATCH',
    });
}

export function callUpdate(url: string, data: any) {
    return callApi({
        url,
        data,
        method: 'PUT',
    });
}

export function callDelete (url: string) {
    return callApi({
        url,
        method: 'DELETE',
    });
}

