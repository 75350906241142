import {
	Button,
	FormControl,
	FormLabel,
	Input,
	SimpleGrid,
	Text,
	Box,
	useColorModeValue, Flex, Select
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, {useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {CheckIcon} from "@chakra-ui/icons";
import {useNavigate} from "react-router-dom";

export default function CompanyInfoPage() {
	const [validationError, setValidationError] = useState('');
	const navigate = useNavigate();

	//
	// async function fetchData() {
	// 	try {
	// 		const accessToken = await getAccessTokenSilently();
	// 		const data = await getCompany(accessToken);
	// 		setCompanyData(data.company);
	// 		// console.log(data);
	// 		setCompanyAddress(data.company.address);
	// 		setCompanyName(data.company.name);
	// 		setCountry(data.company.country);
	// 		setCompanyRegistrationNumber(data.company.registrationNumber);
	// 		if(data.company.address && data.company.name && data.company.registrationNumber) {
	// 			setIsCompanyInfoPrefilled(true);
	// 		}
	// 	} catch (error) {
	// 		console.error('Error fetching company data:', error);
	// 	}
	// }

	// useEffect(() => {
	// 	if (isLoading) {
	// 		return;
	// 	}
	//
	// 	if (isAuthenticated) {
	// 		fetchData();
	// 	}
	// }, [isLoading, isAuthenticated]);

	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<Card w={{ base: "100%", md: "50%" }} mb={{ base: '0px', '2xl': '20px' }} gridArea={{ base: '2 / 1 / 3 / 2', lg: '1 / 2 / 2 / 3' }} minH='365px' pe='20px'>
				<Text fontSize="xl" fontWeight="bold" mb={4}>Profile</Text>
			</Card>
		</Box>
	);
}
