import React, {useEffect} from 'react';
import ReactDOM, {createRoot} from "react-dom/client";
import './assets/css/App.css';
import {HashRouter, Route, Routes, Navigate, useNavigate} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import DashboardLayout from './layouts/dashboard';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import 'flag-icon-css/css/flag-icons.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Cookies from "universal-cookie";
import { IntercomProvider, useIntercom } from 'react-use-intercom';

const INTERCOM_APP_ID = 'hfnxr7gr';

const cookies = new Cookies();

function ProtectedRoute() {
	const navigate = useNavigate();
	const cookies = new Cookies();
	const authToken = cookies.get('authToken');


	useEffect(() => {
		// if (authToken) {
		// 	navigate("/dashboard/default");
		// } else {
			navigate("/auth/sign-in");
		// }
	}, [navigate]);

	return <></>;
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
	<IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<HashRouter>
					<Routes>
						<Route path="/auth/*" element={<AuthLayout />} />
						<Route path="/dashboard/*" element={<DashboardLayout />} />
						<Route path="/" element={<ProtectedRoute />} />
					</Routes>
				</HashRouter>
			</React.StrictMode>
		</ChakraProvider>
	</IntercomProvider>
);
