import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { Icon } from '@chakra-ui/icons';
import { FcGoogle } from 'react-icons/fc';
import Cookies from "universal-cookie";
import './../../../assets/css/App.css';

const cookies = new Cookies();

function SignIn() {
    const navigate = useNavigate();
    const buttonBg = useColorModeValue("gray.100", "gray.700");
    const buttonHoverBg = useColorModeValue("gray.200", "gray.600");

    useEffect(() => {
        const authToken = window.location.href.split('authToken=')[1];
        console.log('authToken: ', authToken);
        if (authToken) {
            cookies.set('authToken', authToken, { path: '/' });
            navigate("/dashboard/default");
        }
    }, [navigate]);

    return (
        <Flex
            w="100vw"
            h="100vh"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            bg='rgb(135, 138, 204)'
        >
            <Box bg="white" boxShadow='lg' w="400px" h="auto" borderRadius="lg" p="20px">
                <Flex flexDirection="column" alignItems="center" width="100%">
                    <Text fontSize="2xl" mb="5px" textAlign="center"><b>Welcome to Tallic!</b></Text>
                    <Text mt="20px" color="gray.600">
                        Build your custom GPT with wallet functionality.
                    </Text>
                    <Flex justifyContent="center" mt="15px" mb="25px" flexDirection="column" alignItems="center">
                        <Button
                            w="full"
                            mt="5px"
                            minH="52px"
                            bg={buttonBg}
                            color="black"
                            leftIcon={<Icon as={FcGoogle} w={5} h={5} />}
                            onClick={() => window.location.href=`${process.env.REACT_APP_BACKEND_URL || 'https://api-st.tallic.io'}/auth/google-redirect`}
                            _hover={{
                                bg: buttonHoverBg,
                            }}
                        >
                            Sign in with Google
                        </Button>
                        <Text mt="10px" color="gray.500" fontSize={'sm'}>
                            Just here to explore? Try our
                            <a href="https://chat.openai.com/g/g-cknFMzyzR-walletgpt-built-with-tallic-io"
                               target="_blank"
                               rel="noopener noreferrer"
                               style={{ marginLeft: '5px', color: 'blue', textDecoration: 'underline' }}>
                                prebuilt custom GPT
                            </a>
                        </Text>
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
}

export default SignIn;
