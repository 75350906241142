// Chakra imports
import {Portal, Box, useDisclosure, useColorModeValue} from '@chakra-ui/react';
import Footer from 'components/footer/FooterDashboard';
// Layout components
import Navbar from 'components/navbar/NavbarDashboard';
import Sidebar from 'components/sidebar/Sidebar';
import { SidebarContext } from 'contexts/SidebarContext';
import React, { useState } from 'react';
import { Route, Routes} from 'react-router-dom';
import routes from 'routes';
import DefaultRedirect from "../../components/defaultRedirect/defaultRedirect";
import SignIn from "../../views/auth/signIn";

// Custom Chakra theme
export default function Dashboard(props: { [x: string]: any }) {
	const { ...rest } = props;
	// states and functions
	const [ fixed ] = useState(false);
	const [ toggleSidebar, setToggleSidebar ] = useState(false);
	// functions for changing the states from components

	// const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

	// async function checkUserParams() {
	// 	if (isAuthenticated) {
	// 		const accessToken = await getAccessTokenSilently();
	// 		const user = await getUser(accessToken);
	// 		const company = await getCompany(accessToken);
	// 		if(user.user.role === RoleEnum.Admin) {
	// 			setIsUserAdmin(true);
	// 		}
	// 		setCompanyCountry(company.company.country);
	// 	}
	// }
	// checkUserParams();

	const getActiveRoute = (routes: RoutesType[]): string => {
		let activeRoute = 'Default Brand Text';
		for (let i = 0; i < routes.length; i++) {
			if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].name;
			}
		}
		return activeRoute;
	};
	const getActiveNavbar = (routes: RoutesType[]): boolean => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].secondary;
			}
		}
		return activeNavbar;
	};
	const getActiveNavbarText = (routes: RoutesType[]): string | boolean => {
		let activeNavbar = false;
		for (let i = 0; i < routes.length; i++) {
			if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].name;
			}
		}
		return activeNavbar;
	};
	const getRoutes = (routes: RoutesType[]): any => {
		return routes.map((route: RoutesType, key: any) => {
			if (route.layout === '/dashboard') {
				return <Route path={route.path} element={React.createElement(route.component)} key={key} />;
			} else {
				return null;
			}
		});
	};
	document.documentElement.dir = 'ltr';
	const dashBg = useColorModeValue('white', 'navy.900');
	const { onOpen } = useDisclosure();
	return (
		<Box>
			<SidebarContext.Provider
				value={{
					toggleSidebar,
					setToggleSidebar
				}}>
				<Box
					bg={dashBg}
					float='right'
					minHeight='100vh'
					height='100%'
					position='relative'
					w='100%'
					transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
					transitionDuration='.2s, .2s, .35s'
					transitionProperty='top, bottom, width'
					transitionTimingFunction='linear, linear, ease'>
					<Box mx='auto' minH='100vh'>
						<Routes>
							{getRoutes(routes)}
							<Route path="*" element={<SignIn />} />
						</Routes>
					</Box>
				</Box>
			</SidebarContext.Provider>
		</Box>
	);
}
