/**
 * Tallic API
 * Tallic backend API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface InteractionCountDto { 
    type: InteractionCountDto.TypeEnum;
    count: number;
}
export namespace InteractionCountDto {
    export type TypeEnum = 'BALANCE_CHECK' | 'ADDRESS_CHECK' | 'TRANSACTION_STATUS_CHECK' | 'USER_WITH_WALLET_CREATED' | 'TRANSFER' | 'SWAP' | 'TENANT_CREATED' | 'TENANT_GPT_CALLBACK_LINKED';
    export const TypeEnum = {
        BalanceCheck: 'BALANCE_CHECK' as TypeEnum,
        AddressCheck: 'ADDRESS_CHECK' as TypeEnum,
        TransactionStatusCheck: 'TRANSACTION_STATUS_CHECK' as TypeEnum,
        UserWithWalletCreated: 'USER_WITH_WALLET_CREATED' as TypeEnum,
        Transfer: 'TRANSFER' as TypeEnum,
        Swap: 'SWAP' as TypeEnum,
        TenantCreated: 'TENANT_CREATED' as TypeEnum,
        TenantGptCallbackLinked: 'TENANT_GPT_CALLBACK_LINKED' as TypeEnum
    };
}


