import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Button,
	Flex,
	Text,
	Input,
	Heading,
	VStack,
	Divider,
	OrderedList,
	Link, ListItem, UnorderedList,
	Code, IconButton, Spinner, HStack, Icon,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	useDisclosure,
	TabList, Tabs, Tab, AlertIcon, Alert, AlertTitle, AlertDescription
} from "@chakra-ui/react";
import {getTenant, postCallbackUri} from "../../../services/tenant";
import { TenantDto } from "../../../generated-client/model/tenant-dto";
import {ChatIcon, CheckIcon, CopyIcon} from "@chakra-ui/icons";
import {useIntercom} from "react-use-intercom";
import {InteractionCountDto} from "../../../generated-client/model/interaction-count-dto";
import {getInteractionsCount} from "../../../services/interactions";

function MainDashboard() {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [showSuccess, setShowSuccess] = useState(false);
	const [tenant, setTenant] = useState<TenantDto>();
	const [stats, setStats] = useState<InteractionCountDto[]>();
	const { boot, shutdown, hide, show, update } = useIntercom();
	const [callbackUri, setCallbackUri] = useState<string>();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [setupType, setSetupType] = useState('userWallet');
	// Dummy video URL - replace with actual URL
	const videoUrl = "https://video-wlt-gpt.s3.us-east-2.amazonaws.com/tallic-setup.mp4";

	async function fetchData() {
		try {
			setIsLoading(true);
			const data = await getTenant();
			const stats = await getInteractionsCount();
			setStats(stats.interactions);
			setTenant(data.tenant);
			setCallbackUri(data.tenant.callBackUri);
		} catch (error) {
			console.error("Error fetching company data:", error);
		} finally {
			setIsLoading(false);
		}
	}

	async function saveCallbackUrl() {
		try {
			setIsLoading(true);
			await postCallbackUri(callbackUri);
			setShowSuccess(true);
			setTimeout(() => setShowSuccess(false), 3000);
		} catch (error) {
			console.error("Error saving callback URL:", error);
		} finally {
			setIsLoading(false);
		}
	}

	// New component to display interaction boxes
	function InteractionBoxes({ stats }: { stats: InteractionCountDto[]}) {
		const interactionTypes = {
			USER_WITH_WALLET_CREATED: "Wallets",
			TRANSFER: "Transfers",
			SWAP: "Swaps"
		};

		return (
			<HStack spacing={4}>
				{Object.entries(interactionTypes).map(([key, label]) => {
					const count = stats.find(stat => stat.type === key)?.count || 0;
						return (
							<Box key={key} p={4} bg="gray.100" minW='100px' borderRadius="md">
								<Text fontSize="lg">{label}</Text>
								<Text fontSize="2xl">{count}</Text>
							</Box>
						);
					})
				}
			</HStack>
		);
	}


	useEffect(() => {
		fetchData();
	}, []);


	return (
		<Flex
			w="100vw"
			h="auto" // Adjusted for better mobile view
			alignItems="center"
			justifyContent="center"
			flexDirection="column"
			bg='rgb(135, 138, 204)'
			p={4} // Padding for mobile screens
		>
			<Box bg="white" boxShadow="lg" w={{ base: "90%", md: "80%" }} maxW="960px" borderRadius="lg" p="20px">
				<VStack spacing={4} px={{ base: 4, md: 20 }}>
					<Heading as="h1" size="xl" textAlign="center">
						Welcome to Tallic BETA
					</Heading>
					<Text>
						We are here to empower your GPT with a crypto wallet. Below you will
						find integration instructions.
					</Text>
					<Divider />
					{stats && stats.find(s => s.type === InteractionCountDto.TypeEnum.UserWithWalletCreated)?.count > 0 && (
						<Heading as="h2" size="md">
							<b>Usage statistics</b>
						</Heading>
					)
					}
					{stats && stats.find(s => s.type === InteractionCountDto.TypeEnum.UserWithWalletCreated)?.count > 0 && <InteractionBoxes stats={stats} />}
					{stats && stats.find(s => s.type === InteractionCountDto.TypeEnum.UserWithWalletCreated)?.count > 0 && <Divider />}
					<Heading as="h2" size="md">
						<b>Note</b>
					</Heading>
					<Text>
						<UnorderedList>
							<ListItem>Every user of custom GPT will need to authenticate
						using Google for wallet security and access protection. </ListItem>
							<ListItem>We currently
								support only the Polygon chain in this BETA version. </ListItem>
								<ListItem>The available
						functionalities include getting addresses, checking balances,
								transferring, and swapping through a DEX aggregator. </ListItem>
							<ListItem>More features
						like staking and yield farming are coming soon.</ListItem>
							<ListItem>Remember, this is a
						BETA version, and GPT technology is still early-stage, so invest
						wisely.
							</ListItem>
						</UnorderedList>
					</Text>
					<Divider />
					<Tabs>
						<TabList>
							<Tab onClick={() => setSetupType('userWallet')}>Wallet per User</Tab>
							<Tab onClick={() => setSetupType('centralWallet')}>Central Wallet for GPT</Tab>
						</TabList>
					</Tabs>
					{setupType === 'userWallet' &&
					<>
						<Heading as="h2" size="md">
							Wallet per User
						</Heading>
						<Divider />
						<Text size="sm">
							Every user would get a wallet assigned after they authenticate with Google. Interacting with GPT they would be able to:
							<UnorderedList>
								<ListItem>Check their balance and address</ListItem>
								<ListItem>Initiate transfer of ERC20 or Matic token</ListItem>
								<ListItem>Swap tokens</ListItem>
							</UnorderedList>
						</Text>
						<video width="40%" controls>
							<source src={videoUrl} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
						<Divider />
						<Text size="sm">
							<b>Setup Instructions</b>
						</Text>
						<OrderedList w='100%'>
							<ListItem>
								Set up your custom GPT with any data you want (descriptions, prompts, etc.).
							</ListItem>
							<ListItem>
								At the bottom of the setup page, choose actions, add an action, and import from this URL:{" "}
								<Code>https://api-st.tallic.io/swagger-wallet-per-user-json <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText('https://api-st.tallic.io/swagger-wallet-per-user-json')} aria-label={''}/></Code>
							</ListItem>
							<ListItem>
								For authentication, use OAuth with the following settings:
								<br />
								- Client ID: <Code>{tenant?.apiKey} <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText(tenant?.apiKey)} aria-label={''}/></Code>
								<br />
								- Client Secret: <Code>{tenant?.apiKey} <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText(tenant?.apiKey)} aria-label={''}/></Code>
								<br />
								- Authorization URL: <Code style={{ wordBreak: 'break-all' }}>https://api-st.tallic.io/auth/authorize/{tenant?.apiKey} <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText(`https://api-st.tallic.io/auth/authorize/${tenant?.apiKey}`)} aria-label={''}/></Code>
								<br />
								- Token URL: <Code>https://api-st.tallic.io/auth/token <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText('https://api-st.tallic.io/auth/token')} aria-label={''}/></Code>
								<br />
								- Scope: <Code>all <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText('all')} aria-label={''}/></Code>
								<br />
								- Exchange Method: <Code>Default (POST request)</Code>
							</ListItem>
							<ListItem>
								Enter the privacy policy URL:{" "}
								<Code>https://api-st.tallic.io/privacy <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText('https://api-st.tallic.io/privacy')} aria-label={''}/></Code>
							</ListItem>
							<ListItem>
								Press “Save” and then “Confirm” in the upper right corner.
							</ListItem>
							<ListItem>
								After saving, go back to “Configure”, refresh the page and at the bottom, you'll find a callback URL. Paste it in the input below and click ‘Save'.
							</ListItem>
						</OrderedList>
						{isLoading ? <Spinner /> : (
							<>
								<Input placeholder="Callback URL" value={callbackUri} onChange={(e) => setCallbackUri(e.target.value)} mb={2} />
								<Button colorScheme="blue" onClick={saveCallbackUrl} leftIcon={showSuccess ? <CheckIcon /> : undefined}>
									{showSuccess ? "Saved" : "Save"}
								</Button>
							</>
						)}
					</>
					}


					{setupType === 'centralWallet' &&
						<>
							<Heading as="h2" size="md">
								Central Wallet for GPT
							</Heading>
							<Divider />
							<Text size="sm" w={'100%'}>
								A GPT would get a central wallet assigned to it. It will have the following functions available:
								<UnorderedList>
									<ListItem>Check balances</ListItem>
									<ListItem>Spin off a temporary wallet for the user to accept deposit</ListItem>
									<ListItem>Transfer funds from temporary user wallet to central GPT wallet</ListItem>
									<ListItem>Transfer funds from central GPT wallet to temporary user address</ListItem>
								</UnorderedList>
							</Text>
							<Alert status="warning" bg="lightyellow">
								<AlertIcon />
								<Box flex="1">
									<AlertTitle>Important Notice:</AlertTitle>
									<AlertDescription display="block">
										Please note, this setup gives full control of the wallet to custom GPT, which means you need to be very careful and craft your custom GPT configuration to avoid any potential hacks. It is essential to test it multiple times.
									</AlertDescription>
								</Box>
							</Alert>
							<Divider />
							<Text size="sm">
								<b>Setup Instructions</b>
							</Text>
							<OrderedList w='100%'>
								<ListItem>
									Set up your custom GPT with any data you want (descriptions, prompts, etc.).
								</ListItem>
								<ListItem>
									At the bottom of the setup page, choose actions, add an action, and import from this URL:{" "}
									<Code>https://api-st.tallic.io/swagger-central-wallet-json <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText('https://api-st.tallic.io/swagger-central-wallet-json')} aria-label={''}/></Code>
								</ListItem>
								<ListItem>
									For authentication, use API key with the following settings:
									<br />
									- Authentication Type: <Code>API Key </Code>
									<br />
									- API Key: <Code>{tenant?.apiKey} <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText(tenant?.apiKey)} aria-label={''}/></Code>
									<br />
									- Auth Type: <Code>Custom </Code>
									<br />
									- Custom Header Name: <Code>X-API-KEY <IconButton icon={<CopyIcon />} onClick={() => 'X-API-KEY'} aria-label={''}/></Code>
								</ListItem>
								<ListItem>
									Enter the privacy policy URL:{" "}
									<Code>https://api-st.tallic.io/privacy <IconButton icon={<CopyIcon />} onClick={() => navigator.clipboard.writeText('https://api-st.tallic.io/privacy')} aria-label={''}/></Code>
								</ListItem>
								<ListItem>
									Press “Save” and then “Confirm” in the upper right corner.
								</ListItem>
							</OrderedList>
						</>
					}


					<Text>
						Need help? Join us on <Link href="https://t.me/+OeL9fu9ND61hMjc0" color="blue.500" target={'_blank'} isExternal>Telegram</Link> or reach out on <Link as="button" onClick={show} color="blue.500">Intercom</Link>.
					</Text>
				</VStack>
			</Box>
			{/* Video Modal */}
			<Modal isOpen={isOpen} onClose={onClose} size="xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Setup Video</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<video width="100%" controls>
							<source src={videoUrl} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</ModalBody>
				</ModalContent>
			</Modal>
		</Flex>
	);
}

export default MainDashboard;
